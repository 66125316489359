import { createSlice } from "@reduxjs/toolkit";

const initialState = { sidebarShow: false };

const notificationSideBarSlice = createSlice({
  name: "notificationSideBar",
  initialState,
  reducers: {
    setNotificationSideBar: (state, action) => {
      state.sidebarShow = action.payload;
    },
  },
});

export const { setNotificationSideBar } = notificationSideBarSlice.actions;
export default notificationSideBarSlice;
