import React, { useEffect, useState } from "react";
import { useResendOTP } from "src/lib/reactQuery/quires&mutations";
import { showToast } from "./Toast";
import Button from "./Button";

const ResendOTP = ({ emailToResend, className }) => {
  const [timer, setTimer] = useState(60);

  const { mutateAsync: resendOTP, isPending: resendOTPPending } =
    useResendOTP();

  useEffect(() => {
    let countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(countdown);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  const handleResendOTP = async () => {
    let bodyData = {
      verificationType: "email",
      // email: localStorage.getItem("forgotPasswordEmail"),
      email: emailToResend,
    };

    const resend = await resendOTP(bodyData);

    if (!resend) {
      return showToast({
        color: "danger",
        message: "Something went wrong please try again",
      });
    }

    if (resend.success) {
      showToast({
        color: "success",
        message: user.message,
      });
      setTimer(60);
    } else {
      showToast({
        color: "danger",
        message: user.message,
      });
    }
  };

  return (
    <div
      className={`d-flex align-items-center gap-1 ${className || ""}`}
      style={{ minHeight: "30px" }}
    >
      <span>Didn't get OTP?</span>
      {timer > 0 ? (
        <span>Resend in {timer}s </span>
      ) : (
        <Button
          text="Resend OTP"
          color="link"
          onClick={handleResendOTP}
          className="p-0"
          disabled={timer > 0 || resendOTPPending}
        />
      )}
    </div>
  );
};

export default ResendOTP;
