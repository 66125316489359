import React, { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderActions } from "src/redux/slices/headerActions";

const HeaderActions = () => {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const headerActions = useSelector(
    (state) => state.headerActions.headerActions
  );

  const clearHeaderActions = () => {
    dispatch(setHeaderActions([]));
  };

  useEffect(() => {
    // clear headet actions on route change
    clearHeaderActions();
  }, [location]);

  return (
    <div className="d-flex flex-wrap gap-2 ms-auto">
      {headerActions?.map((action,index) => <Fragment key={index}>{action}</Fragment>)}
    </div>
  );
};

export default HeaderActions;
