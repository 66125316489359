import { cilEnvelopeClosed, cilLocationPin, cilPhone } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import React, { Fragment } from "react";
import Button from "./Button";

const DetailsProfileSection = ({ data, btnGroup1, btnGroup2 }) => {
  if (!data) return;

  return (
    <aside className="detail-profile-section">
      <div className="d-flex flex-column align-items-center gap-2">
        <img
          src={data.profileImg}
          alt="profile"
          width={150}
          height={150}
          className="rounded-circle object-fit-cover"
        />
        <h6 className="text-center m-0">{data.name}</h6>
      </div>

      <hr className="m-0 d-none d-xl-block" />

      <div className="flex-grow-1 d-flex flex-column justify-content-between gap-3">
        <div className="d-flex flex-column gap-2">
          {data.profileInfo?.map((info, index) => (
            <div key={index} className="detail-profile-info-item">
              <div>
                {info.icon}
                {info.label}
              </div>
              <p className="m-0 fw-medium">{info.value}</p>
            </div>
          ))}
        </div>

        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-column gap-2">
            {btnGroup1?.map((btn, index) => (
              <Fragment key={index}>{btn}</Fragment>
            ))}
          </div>
          <div className="d-flex flex-wrap gap-2">
            {btnGroup2?.map((btn, index) => (
              <Fragment key={index}>{btn}</Fragment>
            ))}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default DetailsProfileSection;
