import CIcon from "@coreui/icons-react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import React from "react";
import Button from "../Button";
import { cilPlus } from "@coreui/icons";
import headerDropDownActions from "../../constant/headerDropDownActions";

const ActionsDropdown = () => {
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="p-0" caret={false}>
        <Button
          startIcon={<CIcon icon={cilPlus} />}
          color="info"
          className={"p-2 rounded-circle"}
        />
      </CDropdownToggle>
      <CDropdownMenu className="py-0" placement="bottom-end">
        {headerDropDownActions.map((item, index) => (
          <CDropdownItem key={index} href={item.to}>
            <span className="me-2">{item.icon}</span>
            {item.name}
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default ActionsDropdown;
