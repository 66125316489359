import { CButton, CSpinner } from "@coreui/react";
import React from "react";

const Button = ({
  text,
  startIcon,
  endIcon,
  color = "primary",
  size,
  type = "button",
  variant,
  loading,
  className,
  ...props
}) => {
  //   color: "primary" |
  //     "secondary" |
  //     "success" |
  //     "danger" |
  //     "warning" |
  //     "info" |
  //     "light" |
  //     "dark" | "link";
  //   size: "sm" | "lg";
  //   type: "button" | "submit";
  //   variant: "outline" | "ghost";
  return (
    <>
      <CButton
        color={color}
        size={size}
        type={type}
        variant={variant}
        disabled={loading}
        className={className || ""}
        {...props}
      >
        {loading ? (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <CSpinner size="sm" />
            <span>Loading...</span>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center gap-2">
            {startIcon || ""}
            {text}
            {endIcon || ""}
          </div>
        )}
      </CButton>
    </>
  );
};

export default Button;
