import { CSpinner } from "@coreui/react";
import React, { Suspense } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { authRoutes, dashboardRoutes } from "./routes";

const loading = (
  <div className="d-flex align-items-center justify-content-center min-vh-100">
    <CSpinner color="primary" />
  </div>
);

// Layouts
const AuthLayout = React.lazy(() => import("../layout/AuthLayout"));
const DashboardLayout = React.lazy(() => import("../layout/DashboardLayout"));
// Pages
const Page404 = React.lazy(() => import("../pages/page404/Page404"));
const Page500 = React.lazy(() => import("../pages/page500/Page500"));

const AppRoutes = () => {
  return (
    <>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route element={<AuthLayout />}>
              {authRoutes.map((route, index) => {
                return (
                  route.element && (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<route.element />}
                    />
                  )
                );
              })}
            </Route>

            <Route element={<DashboardLayout />}>
              {dashboardRoutes.map((route, index) => {
                return (
                  route.element && (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<route.element />}
                    />
                  )
                );
              })}
            </Route>

            <Route path="/" element={<Navigate to="dashboard" replace />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  );
};

export default AppRoutes;
