import React from "react";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";

const headerDropDownActions = [
  {
    name: "Add User",
    icon: <CIcon icon={cilPlus} />,
    to: "#/users/add",
  },
  {
    name: "Add Farmer",
    icon: <CIcon icon={cilPlus} />,
    to: "#/farmer/add",
  },
  {
    name: "Add Survey",
    icon: <CIcon icon={cilPlus} />,
    to: "#/survey/add",
  },
  {
    name: "Add Weight Management",
    icon: <CIcon icon={cilPlus} />,
    to: "#/weight-management/add",
  },
];

export default headerDropDownActions;
