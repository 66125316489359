import { createSlice } from "@reduxjs/toolkit";

const initialState = { headerActions: [] };

const headerActionsSlice = createSlice({
  name: "headerActions",
  initialState,
  reducers: {
    setHeaderActions: (state, action) => {
      state.headerActions = action.payload;
    },
  },
});

export const { setHeaderActions } = headerActionsSlice.actions;
export default headerActionsSlice;
