import { cilFile } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CPagination,
  CPaginationItem,
  CRow,
  CTable,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import Button from "./Button";
import { DataGrid } from "@mui/x-data-grid";

const itemPerPageOptions = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "30",
    value: 30,
  },
];

const Table = ({ columns, rows, tableTitle, rowActions }) => {
  const [tableColumns, setTableColumns] = useState([]);
  const [tabelRows, setTabelRows] = useState([]);

  const setRenderedColumnsCell = () => {
    const renderedColumns = columns.filter((column) => !!column.randerCell);
    const renderRows = rows.map((row) => {
      for (let column of renderedColumns) {
        row[column.key] = column.randerCell(row);
      }
      return row;
    });
    setTabelRows(renderRows);
  };

  useEffect(() => {
    if (!columns || !rows) return;
    setTableColumns(columns);
    setRenderedColumnsCell();
  }, [columns, rows]);

  const handleSearch = (e) => {};
  const exportData = () => {};

  return (
    <>
      <div className="card-head mb-12">
        <h2 className="h4">{tableTitle || ""}</h2>

        <div className="d-flex flex-wrap align-items-center gap-2">
          <CFormInput
            type="text"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            className="w-auto"
          />
          <Button
            color="secondary"
            text={"Export"}
            startIcon={<CIcon icon={cilFile} />}
            onClick={() => exportData()}
          />
        </div>
      </div>

      <div className="my-table mb-2">
        <CTable
          hover
          responsive
          align="middle"
          className="mb-0 border"
          columns={tableColumns}
          items={tabelRows}
        />
      </div>

      <div className="d-flex flex-wrap justify-content-between align-items-start gap-3 column-gap-2 pt-1">
        <CFormLabel className="d-flex align-items-center gap-2 text-nowrap m-0">
          Rows per page
          <CFormSelect options={itemPerPageOptions} />
        </CFormLabel>

        <div className="d-flex align-items-center gap-2 text-nowrap ms-auto">
          <span className="lh-1">0 - 10 of 50</span>
          <CPagination
            aria-label="Page navigation example"
            className="m-0 pointer"
          >
            <CPaginationItem aria-label="Previous" disabled>
              <span aria-hidden="true">Previous</span>
            </CPaginationItem>

            <CPaginationItem aria-label="Next">
              <span aria-hidden="true">Next</span>
            </CPaginationItem>
          </CPagination>
        </div>
      </div>
    </>
  );
};

export default Table;

// const columns = [
//   {
//     key: "id",
//     label: "#",
//   },
//   {
//     key: "name",
//     label: "Name",
//   },
//   {
//     key: "email",
//     label: "Email",
//   },
//   {
//     key: "phone",
//     label: "Phone",
//   },
// ];
// const rows = [
//   {
//     id: 1,
//     name: "Mark",
//     email: "mark@gmail.com",
//     phone: "9568752864",
//   },
//   {
//     id: 2,
//     name: "John",
//     email: "john@gmail.com",
//     phone: "9658745896",
//   },
//   {
//     id: 3,
//     name: "Jack",
//     email: "jack@gmail.com",
//     phone: "9589745896",
//   },
// ];
