import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { AppSidebarNav } from "./AppSidebarNav";

import { logoNegative } from "src/assets/brand/logo-negative";
import { sygnet } from "src/assets/brand/sygnet";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation from "../../constant/_nav";

import {
  setSidebarFoldable,
  setSidebarShow,
} from "src/redux/slices/sideBarSlice";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const sidebarFoldable = useSelector((state) => state.sideBar.sidebarFoldable);
  const sidebarShow = useSelector((state) => state.sideBar.sidebarShow);

  const setFoldableFalseInSmall = () => {
    if (window.innerWidth < 1200) {
      dispatch(setSidebarFoldable(false));
      dispatch(setSidebarShow(false));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", setFoldableFalseInSmall);
  }, []);

  return (
    <div>
      <CSidebar
        position="fixed"
        unfoldable={sidebarFoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => dispatch(setSidebarShow(visible))}
      >
        <CSidebarBrand className="flex" to="/">
          <CIcon
            className="sidebar-brand-full"
            icon={logoNegative}
            height={35}
          />
          <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
        </CSidebarBrand>
        <CSidebarNav>
          <SimpleBar>
            <AppSidebarNav items={navigation} />
          </SimpleBar>
        </CSidebarNav>
        <CSidebarToggler
          className="d-none d-xl-flex"
          onClick={() => dispatch(setSidebarFoldable(!sidebarFoldable))}
        />
      </CSidebar>
      {sidebarShow && (
        <div
          className="my-sidebar-backdrop d-xl-none"
          onClick={() => dispatch(setSidebarShow(false))}
        />
      )}
    </div>
  );
};

export default React.memo(AppSidebar);
