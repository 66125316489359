import CIcon from "@coreui/icons-react";
import { CSidebar, CSidebarBrand, CSidebarToggler } from "@coreui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoNegative } from "src/assets/brand/logo-negative";
import { sygnet } from "src/assets/brand/sygnet";
import SimpleBar from "simplebar-react";
import { setNotificationSideBar } from "src/redux/slices/notificationSidebar";

const NotificationSideBar = () => {
  const dispatch = useDispatch();

  const sideBarShow = useSelector(
    (state) => state.notificationSideBar.sidebarShow
  );

  return (
    <div>
      <CSidebar
        position="fixed"
        visible={sideBarShow}
        unfoldable={false}
        className="sidebar-end"
        onVisibleChange={(visible) => dispatch(setNotificationSideBar(visible))}
      >
        <div style={{ color: "var(--cui-body-color)" }}>
          <div className=" p-12 border-bottom">
            <h3 className="h5 text-center m-0">Notifications</h3>
          </div>
          <ul className="list-unstyled m-0">
            <li className="font-weight-bold p-12">Notification 1</li>
          </ul>
        </div>
      </CSidebar>
      {sideBarShow && (
        <div
          className="my-sidebar-backdrop"
          onClick={() => dispatch(setNotificationSideBar(false))}
        />
      )}
    </div>
  );
};

export default NotificationSideBar;
