import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilCursor,
  cilFactory,
  cilGroup,
  cilSpeedometer,
  cilStar,
  cilUser,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    // badge: {
    //   color: "info",
    //   text: "NEW",
    // },
  },
  {
    component: CNavItem,
    name: "Users",
    to: "/users",
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Roles",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Roles",
        to: "/roles",
      },
      {
        component: CNavItem,
        name: "Manage Roles",
        to: "/manage-roles",
      },
    ],
  },
  {
    component: CNavTitle,
    name: "Title",
  },
  {
    component: CNavGroup,
    name: "Purchase",
    icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Farmer",
        to: "/farmer",
      },
      {
        component: CNavItem,
        name: "Survey",
        to: "/survey",
      },
      {
        component: CNavItem,
        name: "Weight Management",
        to: "/weight-management",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Production",
    to: "/production",
    icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "inventory",
    to: "/inventory",
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "HR & Payroll",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Employee",
        to: "/employee",
      },
      {
        component: CNavItem,
        name: "Attendance",
        to: "/attendance",
      },
      {
        component: CNavItem,
        name: "Employee Tracking",
        to: "/employee-tracking",
      },
      {
        component: CNavItem,
        name: "Salaries",
        to: "/salaries",
      },
    ],
  },
];

export default _nav;
