const apiEndpoints = {
  // auth
  login: {
    url: "/account/login",
    method: "POST",
  },
  signup: {
    url: "/account/signup",
    method: "POST",
  },
  forgotPassword: {
    url: "/account/forgot-password",
    method: "POST",
  },
  resetPassword: {
    url: "/account/reset-password",
    method: "POST",
  },
  resendOTP: {
    url: "/account/resend-otp",
    method: "POST",
  },
  verifyEmail: {
    url: "/account/verify_account",
    method: "POST",
  },

  // dashboard
  getUsers: {
    url: "/user/admin",
    method: "GET",
  },
};

export default apiEndpoints;
