import React, { useRef } from "react";
import { CToast, CToastBody, CToastClose, CToaster } from "@coreui/react";
import store from "src/redux";
import { useSelector } from "react-redux";
import { addToast } from "src/redux/slices/toastSlice";

export const showToast = ({ color = "info", message = "" }) => {
  // color -> success, danger, warning, info

  const exampleToast = (
    <CToast color={color} className="align-items-center">
      <div className="d-flex">
        <CToastBody>
          <span
            className={`fw-semibold fs-6 ${color === "danger" && "text-white"}`}
          >
            {message}
          </span>
        </CToastBody>
        <CToastClose className="me-2 m-auto" white={color === "danger"} />
      </div>
    </CToast>
  );

  setTimeout(() => {
    store.dispatch(addToast(exampleToast));
  }, 0);
};

const toast = () => {
  const toaster = useRef();
  const toast = useSelector((state) => state.toast.toast);

  return <CToaster ref={toaster} push={toast} placement="top-end" />;
};

export default toast;
