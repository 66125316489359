import CIcon from "@coreui/icons-react";
import {
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import React, { useState } from "react";
import { cilSearch } from "@coreui/icons";

const SearchDropdown = () => {
  const [searchValue, setsearchValue] = useState("");

  return (
    <CDropdown variant="nav-item" autoClose="outside">
      <CDropdownToggle
        placement="bottom-end"
        className="p-0 mx-2"
        caret={false}
      >
        <CIcon icon={cilSearch} size="lg" />
      </CDropdownToggle>
      <CDropdownMenu className="py-0 search-dropdown" placement="bottom-end">
        <CDropdownHeader className="p-2">
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilSearch} />
            </CInputGroupText>
            <CFormInput
              placeholder="Search"
              value={searchValue}
              onChange={(e) => setsearchValue(e.target.value)}
            />
          </CInputGroup>
        </CDropdownHeader>
        <CDropdownItem>Search Items</CDropdownItem>
        <CDropdownItem>Search Items</CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default SearchDropdown;
