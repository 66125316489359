import { configureStore } from "@reduxjs/toolkit";
import {
  headerActionsSlice,
  notificationSideBarSlice,
  sideBarSlice,
  toastSlice,
  userSlice,
} from "./slices";

const store = configureStore({
  reducer: {
    [sideBarSlice.name]: sideBarSlice.reducer,
    [notificationSideBarSlice.name]: notificationSideBarSlice.reducer,
    [toastSlice.name]: toastSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [headerActionsSlice.name]: headerActionsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
