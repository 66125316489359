import { createSlice } from "@reduxjs/toolkit";

const initialState = { sidebarShow: true, sidebarFoldable: false };

const sideBarSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload;
    },
    setSidebarFoldable: (state, action) => {
      state.sidebarFoldable = action.payload;
    },
  },
});

export const { setSidebarShow, setSidebarFoldable } = sideBarSlice.actions;
export default sideBarSlice;
