import React from "react";

// auth routes
const Login = React.lazy(() => import("../pages/auth/Login"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const ForgotPassword = React.lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/auth/ResetPassword"));
const verifyEmail = React.lazy(() => import("../pages/auth/VerifyEmail"));

// dashboard routes
const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
// user routes
const Users = React.lazy(() => import("../pages/dashboard/users"));
const UserDetails = React.lazy(() =>
  import("../pages/dashboard/users/UserDetails")
);
const AddEditUser = React.lazy(() =>
  import("../pages/dashboard/users/AddEditUser")
);
// roles routes
const Roles = React.lazy(() => import("../pages/dashboard/roles"));
const ManageRoles = React.lazy(() =>
  import("../pages/dashboard/roles/ManageRoles")
);
// purchase routes
const Farmer = React.lazy(() => import("../pages/dashboard/purchase/farmer"));
const FarmerDetails = React.lazy(() =>
  import("../pages/dashboard/purchase/farmer/FarmerDetails")
);
const AddEditFarmer = React.lazy(() =>
  import("../pages/dashboard/purchase/farmer/AddEditFarmer")
);
const Survey = React.lazy(() => import("../pages/dashboard/purchase/survey"));
const SurveyDetails = React.lazy(() =>
  import("../pages/dashboard/purchase/survey/SurveyDetails")
);
const AddEditSurvey = React.lazy(() =>
  import("../pages/dashboard/purchase/survey/AddEditSurvey")
);
const WeightManagement = React.lazy(() =>
  import("../pages/dashboard/purchase/weightManagement")
);
const AddEditWeightManagement = React.lazy(() =>
  import("../pages/dashboard/purchase/weightManagement/AddEditWeightManagement")
);
// production routes
const Production = React.lazy(() => import("../pages/dashboard/production"));
// inventory routes
const Inventory = React.lazy(() => import("../pages/dashboard/inventory"));
// HR&Payroll routes
const Employee = React.lazy(() =>
  import("../pages/dashboard/HR&Payroll/employee")
);
const EmployeeDetails = React.lazy(() =>
  import("../pages/dashboard/HR&Payroll/employee/EmployeeDetails")
);
const AddEditEmployee = React.lazy(() =>
  import("../pages/dashboard/HR&Payroll/employee/AddEditEmployee")
);
const Attendance = React.lazy(() =>
  import("../pages/dashboard/HR&Payroll/attendance")
);
const EmployeeTracking = React.lazy(() =>
  import("../pages/dashboard/HR&Payroll/employeeTracking")
);
const Salaries = React.lazy(() =>
  import("../pages/dashboard/HR&Payroll/salaries")
);

export const authRoutes = [
  { path: "/login", name: "Login Page", element: Login },
  { path: "/register", name: "Register Page", element: Register },
  {
    path: "/forgot-password",
    name: "Forgot Password Page",
    element: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Reset Password Page",
    element: ResetPassword,
  },
  {
    path: "/verify-email",
    name: "verify Email Page",
    element: verifyEmail,
  },
];

export const dashboardRoutes = [
  { path: "/", name: "Home", exact: true },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  // user routes
  { path: "/users", name: "Users", element: Users },
  { path: "/users/:id", name: "User Details", element: UserDetails },
  { path: "/users/add", name: "Add User", element: AddEditUser },
  // roles routes
  {
    path: "/roles",
    name: "Roles",
    element: Roles,
  },
  {
    path: "/manage-roles",
    name: "Manage Roles",
    element: ManageRoles,
  },
  // purchase routes
  { path: "/farmer", name: "Farmer", element: Farmer },
  { path: "/farmer/:id", name: "Farmer Details", element: FarmerDetails },
  { path: "/farmer/add", name: "Add Farmer", element: AddEditFarmer },
  { path: "/survey", name: "Survey", element: Survey },
  { path: "/survey/:id", name: "Survey Details", element: SurveyDetails },
  { path: "/survey/add", name: "Add Survey", element: AddEditSurvey },
  {
    path: "/weight-management",
    name: "Weight Management",
    element: WeightManagement,
  },
  {
    path: "/weight-management/add",
    name: "Add Weight Management",
    element: AddEditWeightManagement,
  },
  // production routes
  { path: "/production", name: "Production", element: Production },
  // inventory routes
  { path: "/inventory", name: "Inventory", element: Inventory },
  // HR&Payroll routes
  { path: "/employee", name: "Employee", element: Employee },
  { path: "/employee/:id", name: "Employee Details", element: EmployeeDetails },
  { path: "/employee/add", name: "Add Employee", element: AddEditEmployee },
  { path: "/attendance", name: "Attendance", element: Attendance },
  {
    path: "/employee-tracking",
    name: "Employee Tracking",
    element: EmployeeTracking,
  },
  { path: "/salaries", name: "Salaries", element: Salaries },
];
