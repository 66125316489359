import apiEndPoints from "../../utilities/apiEndPoints";
import APIrequest from "src/utilities/apiRequest";

export const login = async (bodyData) => {
  bodyData = { ...bodyData, deviceType: "web" };

  try {
    const payload = {
      ...apiEndPoints.login,
      bodyData,
    };

    const response = await APIrequest(payload);

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const signup = async (bodyData) => {
  bodyData = { ...bodyData, deviceType: "web" };

  try {
    const payload = {
      ...apiEndPoints.signup,
      bodyData,
    };

    const response = await APIrequest(payload);

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const forgotPassword = async (bodyData) => {
  try {
    const payload = {
      ...apiEndPoints.forgotPassword,
      bodyData,
    };

    const response = await APIrequest(payload);

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async (bodyData) => {
  try {
    const payload = {
      ...apiEndPoints.resetPassword,
      bodyData,
    };

    const response = await APIrequest(payload);

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const resendOTP = async (bodyData) => {
  try {
    const payload = {
      ...apiEndPoints.resendOTP,
      bodyData,
    };

    const response = await APIrequest(payload);

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const verifyEmail = async (bodyData) => {
  try {
    const payload = {
      ...apiEndPoints.verifyEmail,
      bodyData,
    };

    const response = await APIrequest(payload);

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    console.log(error);
  }
};
