import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CFormInput,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilBell, cilMenu, cilSearch } from "@coreui/icons";

import { AppBreadcrumb } from "../index";
import AppHeaderDropdown from "./AppHeaderDropdown";
import { logo } from "src/assets/brand/logo";
import { setSidebarShow } from "src/redux/slices/sideBarSlice";
import { useLocation } from "react-router-dom";
import { dashboardRoutes } from "src/routes/routes";
import HeaderActions from "./HeaderActions";
import ActionsDropdown from "./ActionsDropdown";
import SearchDropdown from "./SearchDropdown";
import NotificationSideBar from "./NotificationSideBar";
import { setNotificationSideBar } from "src/redux/slices/notificationSidebar";

const AppHeader = () => {
  const currentLocation = useLocation().pathname;
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sideBar.sidebarShow);

  const [searchValue, setSearchValue] = useState("");

  const getCurrentRouteName = () => {
    const currentRoute = dashboardRoutes.find((route) => {
      if (route.path.includes(":id")) {
        console.log("in");
        let pageId = currentLocation.match(/[/][0-9]+/);
        if (!pageId) return false;
        pageId = pageId[0];
        const routePath = route.path.replace("/:id", pageId);
        return routePath === currentLocation;
      } else {
        return route.path === currentLocation;
      }
    });
    return currentRoute ? currentRoute.name : null;
  };

  const getCurrentRouteNameMemo = useMemo(getCurrentRouteName, [
    currentLocation,
  ]);

  return (
    <CHeader position="sticky" className="p-0">
      <CContainer fluid className="header-container py-2">
        <CHeaderToggler
          className="ps-0"
          onClick={() => dispatch(setSidebarShow(!sidebarShow))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>

        <div className="d-flex align-items-center gap-2 me-md-auto">
          <CHeaderNav>
            <ActionsDropdown />
          </CHeaderNav>
          {/* <h1 className="fs-6 d-none d-md-flex mb-0">
            {getCurrentRouteNameMemo}
          </h1> */}
        </div>

        {/* <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand> */}

        <CHeaderNav className="d-flex justify-content-between align-items-center">
          <SearchDropdown />
          <CNavItem className="mx-2 pointer">
            <CIcon
              icon={cilBell}
              size="lg"
              onClick={() => dispatch(setNotificationSideBar(true))}
            />
          </CNavItem>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>

      <CHeaderDivider className="m-0" />

      <CContainer fluid className="flex-wrap py-2 gap-2">
        <AppBreadcrumb />
        <HeaderActions />
      </CContainer>

      <NotificationSideBar />
    </CHeader>
  );
};

export default AppHeader;
