import { createSlice } from "@reduxjs/toolkit";

const initialState = { toast: 0 };

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    addToast: (state, action) => {
      state.toast = action.payload;
    },
  },
});

export const { addToast } = toastSlice.actions;
export default toastSlice;
