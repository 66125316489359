import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

import { dashboardRoutes } from "../routes/routes";

import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";

const activeBreadcrumbStyle = {
  color: "var(--cui-secondary-color)",
  pointerEvents: "none",
  textDecoration: "none",
};

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname;

  const getRouteName = (pathname, routes) => {
    console.log(pathname);
    const currentRoute = routes.find((route) => {
      if (route.path.includes(":id")) {
        let pageId = currentLocation.match(/[/][0-9]+/);
        if (!pageId) return false;
        pageId = pageId[0];
        const routePath = route.path.replace("/:id", pageId);
        return routePath === currentLocation;
      } else {
        return route.path === pathname;
      }
    });
    return currentRoute ? currentRoute.name : false;
  };

  const getBreadcrumbs = (location) => {
    const breadcrumbs = [];
    location.split("/").reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const routeName = getRouteName(currentPathname, dashboardRoutes);
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        });
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <div className="breadcrumbs-wrapper m-0">
      <Link to={breadcrumbs[0].pathname}>
        <h1 className="h5 m-0">{breadcrumbs[0].name}</h1>
      </Link>
      <span className="vr"></span>
      <Link to="/">Home</Link>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <Fragment key={index}>
            <span style={{ color: "var(--cui-secondary-color)" }}>/</span>
            <Link
              style={breadcrumb.active ? activeBreadcrumbStyle : {}}
              to={breadcrumb.pathname}
            >
              {breadcrumb.name}
            </Link>
          </Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(AppBreadcrumb);
