import { useMutation } from "@tanstack/react-query";
import {
  forgotPassword,
  login,
  resendOTP,
  resetPassword,
  signup,
  verifyEmail,
} from "../apis";

export const useLogin = () => {
  return useMutation({
    mutationFn: login,
  });
};

export const useSignup = () => {
  return useMutation({
    mutationFn: signup,
  });
};

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: forgotPassword,
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPassword,
  });
};

export const useResendOTP = () => {
  return useMutation({
    mutationFn: resendOTP,
  });
};

export const useVerifyEmail = () => {
  return useMutation({
    mutationFn: verifyEmail,
  });
};
