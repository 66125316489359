import React, { useEffect } from "react";
import "./scss/style.scss";
import AppRoutes from "./routes";
import { getSessionStorageToken } from "./utilities/common";
import store from "./redux";
import { setIsLoggedIn } from "./redux/slices/userSlice";

const checkLogin = () => {
  const isLoggedIn = getSessionStorageToken();

  if (isLoggedIn) {
    store.dispatch(setIsLoggedIn(true));
    return true;
  }

  return false;
};

const App = () => {
  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <div>
      <AppRoutes />
    </div>
  );
};

export default App;
