import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import React from "react";
import Button from "../Button";

const ConfirmModal = ({ open, setOpen, text, onYes }) => {
  return (
    <CModal alignment="center" visible={open} onClose={() => setOpen(false)}>
      <CModalHeader onClose={() => setOpen(false)}>
        <CModalTitle id="confirmModal">Confirmation</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p className="my-3">{text}</p>
      </CModalBody>
      <CModalFooter>
        <Button text={"No"} color="secondary" onClick={() => setOpen(false)} />
        <Button text={"Yes"} color="primary" onClick={onYes} />
      </CModalFooter>
    </CModal>
  );
};

export default ConfirmModal;
